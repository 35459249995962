import React from 'react';
import './form.css';
import FormfacadeEmbed from "@formfacade/embed-react";

const CareerPopup = ({ togglePopup }) => {
  return (
    <div className="career-popup-overlay" onClick={togglePopup}>
      <div className="career-popup" onClick={(e) => e.stopPropagation()}>
        <FormfacadeEmbed
          formFacadeURL="https://formfacade.com/include/114484987958678702163/form/1FAIpQLSf9GF4aOdXA9n0w24vbBESQ65-lbmJVo1evlTb50Lo4rk4xEA/classic.js/?div=ff-compose"
          onSubmitForm={() => console.log('Form submitted')}
        />
      </div>
    </div>
  );
}

export default CareerPopup;
