import React, { useState, useEffect } from "react";
import Button from "./button/button.jsx";
import Typer from "./typer/typer.jsx";
import Deco from "../../asset/jini.gif";
import CareerPopup from "./enrolform/form.jsx";
import HirePopup from "./hireform/formhire.jsx";
import Spacer from "../spacer/spacer.jsx";
import "./home.css";

const Home = () => {
  const [showCareerPopup, setShowCareerPopup] = useState(false);
  const [showHirePopup, setShowHirePopup] = useState(false);

  // Function to toggle career popup visibility
  const handleShowCareerPopup = () => setShowCareerPopup(true);
  const handleCloseCareerPopup = () => setShowCareerPopup(false);

  // Function to toggle hire popup visibility
  const handleShowHirePopup = () => setShowHirePopup(true);
  const handleCloseHirePopup = () => setShowHirePopup(false);

  // Effect to handle body scroll when any popup is active
  useEffect(() => {
    if (showCareerPopup || showHirePopup) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }

    // Cleanup to ensure scroll is restored when component unmounts
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showCareerPopup, showHirePopup]);

  return (
    <>
      <div className="container">
        <div className="row">
          <Spacer />
          <div className="col-md-7">
            <h1 className="text-start mt-5">Welcome to Better Job</h1>
            <Typer />
            <p>Always ready to make your life better</p>
            <div className="row">
              <div className="col d-flex gap-4 justify-content-start">
                <Button onClick={handleShowCareerPopup} label="I need a Job" />
                <Button onClick={handleShowHirePopup} label="I want to Hire" />
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <img src={Deco} alt="Decorative GIF" className="deco" />
          </div>
        </div>
      </div>

      {/* Career Popup */}
      {showCareerPopup && <CareerPopup togglePopup={handleCloseCareerPopup} />}

      {/* Hire Popup */}
      {showHirePopup && <HirePopup togglePopup={handleCloseHirePopup} />}
    </>
  );
};

export default Home;
